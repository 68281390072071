<template>
  <div>
    <div class="row mt-3 mb-sm-3">
      <div class="col-12">
        <div class="form-inline">

          <div class="col d-none d-sm-block"></div>

          <sgv-input-select
            class="mr-2"
            placeholder="เลือกเครื่องพิมพ์..."
            :options="printers"
            select="id"
            v-model="deviceId">
            <template slot-scope="option">
              {{option.name}}
            </template>
          </sgv-input-select>

          <button
            v-if="canPrint"
            type="button"
            class="btn btn-success form-group mr-2"
            @click="savePaper">
            <fa icon="download"/> บันทึก
          </button>

          <button
            :disabled="isPrinted"
            v-if="canPrint"
            type="button"
            class="btn btn-warning form-group"
            @click="printPaper">
            <fa icon="print"/> พิมพ์
          </button>
        </div>
      </div>
    </div>

    <div class="row justify-content-center" v-if="pdf">
      <div class="col-12">
        <sgv-pdf :file="pdf"></sgv-pdf>
      </div>
    </div>
  </div>
</template>

<script>
import retainMixin from '@/mixins/retain-mixin'
import axios from 'axios'
import debounce from 'lodash/debounce'
import {
  LIST_PRINTER,
  CREATE_ATTACHMENT
} from '../graph/printer'


export default {
  mixins: [retainMixin],
  props: {
    paperId: {
      type: Number,
      required: true
    },
    paperType: {
      type: String,
      required: true
    },
    templateType: {
      type: String,
      required: true
    },
    formData: {
      type: Object,
      required: true
    },
    url: {
      type: String,
      required: true
    },
    filename: {
      type: String,
      required: true
    },
    canPrint: {
      type: Boolean,
      required: false,
      default: true
    }
  },
  data () {
    return {
      pdf: null,
      deviceId: null,
      printers: [],
      rKey: `Paper${this.$form.capitalize(this.paperType)}DetailPrint`,
      rFields: ['deviceId'],
      isPrinted: false,
    }
  },
  apollo: {
    printers: {
      query () {
        return LIST_PRINTER()
      }
    }
  },
  methods: {
    debounceSample: debounce(vm => {
      if (vm.paperId > 0) vm.getSample()
    }, 1000),
    getSample () {
      this.getData().then(res => {
        this.pdf = res.data
      }).catch(() => {
        this.$toasted.global.error('ไม่สามารถโหลดได้')
        this.pdf = null
      })
    },
    getData (deviceId) {
      return this.$axios.get(this.url, {
        responseType: 'blob',
        params: {deviceId}
      })
    },
    savePaper () {
      this.getData()
      .then(res => this.upload(res.data, this.filename))
      .then(() => {
        this.$toasted.global.success("บันทึกสำเร็จ")
      })
      .catch(() => {
        this.$toasted.global.error("ไม่สามารถอัพโหลดได้")
      })
    },
    printPaper () {
      if (this.isPrinted) return

      if (!this.deviceId) {
        this.$toasted.global.error("โปรดเลือกเครื่องพิมพ์")
        return
      }

      this.$toasted.global.success("ส่งไปยังเครื่องพิมพ์")
      this.isPrinted = true

      this.getData(this.deviceId)
      .then(() => {
        this.$toasted.global.success("กำลังพิมพ์...")
        this.isPrinted = false
      })
      .catch(() => {
        this.$toasted.global.error("ไม่สามารถพิมพ์หรือบันทึกไฟล์ได้")
      })
    },
    upload (file, filename) {
      this.$apollo.mutate({
        mutation: CREATE_ATTACHMENT(this.templateType),
        variables: {
          paperType: this.paperType,
          paperId: this.paperId, 
          filename
        }
      })
      .then(res => {
        const config = res.data.createItem
        let formData = new FormData()

        formData.append('acl', 'private')
        formData.append('Content-Type', file.type)
        Object.keys(config.fields).forEach(k => {
          formData.append(k, config.fields[k])
        })
        formData.append('file', file, filename);

        return axios.post(config.url, formData, {
          headers: {
            'Content-Type': 'multipart/form-data'
          }
        })
      })
    },
  },
  watch: {
    paperId: {
      handler () {
        this.debounceSample(this)
      },
      immediate: true
    },
    formData: {
      handler () {
        this.debounceSample(this)
      },
      deep: true
    },
    deviceId: 'setRetaining'
  },
}
</script>

<style lang="css" scoped>
</style>
