<template>
  <div class="mt-4">

    <div v-if="method ==='edit'">
      <sgv-input-text v-model="search" label="ค้นหา"></sgv-input-text>

      <ul>
        <li v-for="item in items" :key="item.id">
          <fa
            icon="check"
            class="text-success pointer"
            @click="addPaper(item)">
          </fa>&nbsp;
          {{item.code}} <span v-show="item.name">({{item.name}})</span>
        </li>
      </ul>
    </div>

    <div class="table-responsive">
      <table class="table">
        <thead>
          <tr>
            <th scope="col" class="text-center">เลขที่</th>
            <th scope="col" class="text-center">ข้อกำหนด</th>
            <th scope="col" class="text-center">ประเภท</th>
            <th scope="col" class="text-center">หัวข้อ</th>
            <th scope="col" class="text-center">กำหนดแล้วเสร็จ</th>
            <th scope="col" class="text-center">หมายเหตุ</th>
          </tr>
        </thead>
        <tbody>
          <tr
            v-for="child in formData.children"
            :key="child.id">
            <td
              class="text-center"
              style="white-space: nowrap;">
              <fa
                v-if="method === 'edit'"
                icon="trash"
                class="text-danger pointer mr-2"
                @dblclick="deletePaper(child.id)">
              </fa>
              {{child.code}}
            </td>
            <td class="text-center">{{child.issueStandard}}</td>
            <td
              class="text-center text-uppercase"
              :class="{
                'text-danger': child.cparType === 'nc',
                'text-warning': child.cparType === 'o'
              }">
              {{child.cparType}}
            </td>
            <td style="white-space: pre-line;">{{child.name}}</td>
            <td
              class="text-center"
              style="white-space: nowrap;">
              {{child.respondedAt | date}}
            </td>
            <td>{{child.remark}}</td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>

<script>
import {
  DROPDOWN_CHILDREN,
  CREATE_PAPER_LINK,
  DESTROY_PAPER_LINK
} from './graph'
import debounce from 'lodash/debounce'

export default {
  props: {
    paperId: {
      type: Number,
      required: true
    },
    paperType: {
      type: String,
      required: true
    },
    templateType: {
      type: String,
      required: true
    },
    formData: {
      type: Object,
      required: true
    },
    method: {
      type: String,
      required: true
    }
  },
  data () {
    return {
      items: [],
      search: ''
    }
  },
  computed: {
    papers () {
      return this.formData.children.filter(v => {
        const name = v.name || ''
        const isCodeFound = v.code.toLowerCase().indexOf(this.search.toLowerCase()) > -1
        const isNameFound = name.toLowerCase().indexOf(this.search.toLowerCase()) > -1
        return isCodeFound || isNameFound
      })
    }
  },
  methods: {
    getList: debounce(function (filter) {
      this.$apollo.query({
        query: DROPDOWN_CHILDREN(this.templateType),
        variables: {
          paperType: this.paperType,
          paperId: this.paperId,
          q: filter
        },
        fetchPolicy: 'network-only'
      })
      .then(res => {
        this.items = res.data.papers
      })
      .catch(() => {
        this.items = []
      })
    }, 150),
    addPaper (paper) {
      this.$apollo.mutate({
        mutation: CREATE_PAPER_LINK(this.templateType),
        variables: {
          paperType: this.paperType,
          parentId: this.paperId,
          childId: paper.id
        }
      })
      .then(() => {
        this.emitUpdated()
        this.$toasted.global.success("เพิ่มสำเร็จ")
      })
      .catch(this.$toasted.global.error)
    },
    deletePaper (paperId) {
      this.$apollo.mutate({
        mutation: DESTROY_PAPER_LINK(this.templateType),
        variables: {
          paperType: this.paperType,
          parentId: this.paperId,
          childId: paperId
        }
      })
      .then(() => {
        this.emitUpdated()
        this.$toasted.global.success("ลบสำเร็จ")
      })
      .catch(this.$toasted.global.error)
    },
    emitUpdated () {
      this.$emit('updated', null)
    }
  },
  watch: {
    search (value) {
      if (value) {
        this.getList({
          limit: 20,
          params: {
            search: value
          },
          order: 'code'
        })
      } else {
        this.items = []
      }
    }
  }
}
</script>

<style lang="css" scoped>
</style>
