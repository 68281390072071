<template>
  <div>
    <div class="form-row">
      <sgv-input-text
        :disabled="$auth.disabled(method)"
        class="col-12 col-sm-4"
        label="รหัส"
        v-model="formData.code">
      </sgv-input-text>

      <sgv-input-text
        :disabled="$auth.disabled(method)"
        class="col-12 col-sm-8"
        label="ชื่อ"
        v-model="formData.name"
        :validations="[
          {text: 'required!', value: v.formData.name.$dirty && v.formData.name.$error}
        ]">
      </sgv-input-text>
    </div>

    <div class="form-row">
      <sgv-input-number
        :disabled="$auth.disabled(method)"
        class="col-4"
        label="ตรวจประเมินครั้งที่"
        v-model="formData.count"
        :validations="[
          {text: 'required!', value: v.formData.count.$dirty && v.formData.count.$error}
        ]">
      </sgv-input-number>

      <sgv-input-text
        :disabled="$auth.disabled(method)"
        class="col-4"
        label="ประจำปี"
        v-model="formData.year"
        :validations="[
          {text: 'required!', value: v.formData.year.$dirty && v.formData.year.$error}
        ]">
      </sgv-input-text>

      <sgv-input-text
        :disabled="$auth.disabled(method)"
        class="col-4"
        label="มาตรฐานอ้างอิง"
        v-model="formData.reference"
        :validations="[
          {text: 'required!', value: v.formData.reference.$dirty && v.formData.reference.$error}
        ]">
      </sgv-input-text>
    </div>

    <div class="form-row">
      <sgv-input-text
        :disabled="$auth.disabled(method)"
        class="col-12"
        label="วัตถุประสงค์"
        v-model="formData.content"
        :validations="[
          {text: 'required!', value: v.formData.content.$dirty && v.formData.content.$error}
        ]">
      </sgv-input-text>
    </div>

    <div class="form-row">
      <sgv-input-datetime
        isNull
        :disabled="$auth.disabled(method)"
        class="col-6"
        label="วันที่ตรวจประเมิน"
        v-model="formData.reportedAt"
        :validations="[
          {text: 'required!', value: !v.formData.reportedAt.required && v.formData.$dirty}
        ]">
      </sgv-input-datetime>

      <sgv-input-text
        :disabled="$auth.disabled(method)"
        class="col-6"
        label="ผู้ร่วมทีมตรวจ"
        v-model="formData.team">
      </sgv-input-text>
    </div>

    <div class="form-row">
      <sgv-input-textarea
        :disabled="$auth.disabled(method)"
        class="col-12 col-md-6"
        auto-grow
        label="ข้อเสนอแนะในการตรวจครั้งถัดไป"
        v-model="formData.approvedRemark">
      </sgv-input-textarea>

      <sgv-input-textarea
        :disabled="$auth.disabled(method)"
        class="col-12 col-md-6"
        auto-grow
        label="ขอเสนอแนะ (QMR)"
        v-model="formData.remark">
      </sgv-input-textarea>
    </div>

    <div class="form-row" v-if="paperId > 0">
      <DetailFormUser
        class="col-6"
        title="จัดทำ/ทบทวน"
        :value="getUser('reviewed')"
        :isSubmit="!formData.approvedAt && !formData.reviewedAt && $auth.hasRole(`paper:${paperType}:review`)"
        :isUndo="!formData.approvedAt && formData.reviewedAt && $auth.hasRole(`paper:${paperType}:review`)"
        :submitFunc="reviewSubmit"
        :undoFunc="reviewUndo"
        @updated="$emit('updated', null)">
      </DetailFormUser>

      <DetailFormUser
        class="col-6"
        title="อนุมัติ"
        :value="getUser('approved')"
        :isSubmit="formData.reviewedAt && !formData.approvedAt && $auth.hasRole(`paper:${paperType}:approve`)"
        :isUndo="formData.reviewedAt && formData.approvedAt && $auth.hasRole(`paper:${paperType}:approve`)"
        :submitFunc="approveSubmit"
        :undoFunc="approveUndo"
        @updated="$emit('updated', null)">
      </DetailFormUser>
    </div>

    <div class="form-row">
      <div class="form-group col-12">
        <sgv-input-check
          :disabled="$auth.disabled(method)"
          label="ใช้งาน"
          inline
          v-model="formData.isActive">
        </sgv-input-check>
      </div>
    </div>
  </div>
</template>

<script>
import DetailFormUser from './DetailFormUser'
import {
  REVIEW_SUBMIT_PAPER,
  REVIEW_UNDO_PAPER,
  APPROVE_SUBMIT_PAPER,
  APPROVE_UNDO_PAPER,
} from './graph'

export default {
  props: {
    paperId: {
      type: Number,
      required: true
    },
    paperType: {
      type: String,
      required: true
    },
    templateType: {
      type: String,
      required: true
    },
    formData: {
      type: Object,
      required: true
    },
    method: {
      type: String,
      required: true
    },
    v: {
      type: Object,
      required: true
    },
  },
  methods: {
    getUser (type) {
      const date = this.formData[type + 'At']
      const user = this.formData[type + 'User']
      if (!date || !user) return ''
      return this.$date.format(date).display + ' | ' + user.name
    },
    reviewSubmit (date) {
      return this.$apollo.mutate({
        mutation: REVIEW_SUBMIT_PAPER(this.templateType),
        variables: {
          paperType: this.paperType,
          paperId: this.paperId,
          submitDate: date
        }
      })
    },
    reviewUndo () {
      return this.$apollo.mutate({
        mutation: REVIEW_UNDO_PAPER(this.templateType),
        variables: {
          paperType: this.paperType,
          paperId: this.paperId,
        }
      })
    },
    approveSubmit (date) {
      return this.$apollo.mutate({
        mutation: APPROVE_SUBMIT_PAPER(this.templateType),
        variables: {
          paperType: this.paperType,
          paperId: this.paperId,
          submitDate: date
        }
      })
    },
    approveUndo () {
      return this.$apollo.mutate({
        mutation: APPROVE_UNDO_PAPER(this.templateType),
        variables: {
          paperType: this.paperType,
          paperId: this.paperId,
        }
      })
    },
  },
  components: {
    DetailFormUser
  }
}
</script>

<style lang="css">

</style>
