import gql from 'graphql-tag'


export const LIST_PRINTER = () => gql`query LIST_PRINTER {
  printers: paperPrinters {
    id type code name
  }
}`

export const CREATE_ATTACHMENT = (templateType) => gql`mutation CREATE_ATTACHMENT ($paperType: String!, $paperId: Int!, $filename: String!) {
  createItem: create${templateType}Attachment (paperType: $paperType, paperId: $paperId, filename: $filename)
}`
