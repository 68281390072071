import gql from 'graphql-tag'


export const LIST_ATTACHMENT = (templateType) => gql`query LIST_ATTACHMENT ($paperType: String!, $paperId: Int!) {
  items: list${templateType}Attachment (paperType: $paperType, paperId: $paperId)
}`

export const DETAIL_ATTACHMENT = (templateType) => gql`query DETAIL_ATTACHMENT ($paperType: String!, $paperId: Int!, $filename: String!) {
  url: detail${templateType}Attachment (paperType: $paperType, paperId: $paperId, filename: $filename)
}`

export const CREATE_ATTACHMENT = (templateType) => gql`mutation CREATE_ATTACHMENT ($paperType: String!, $paperId: Int!, $filename: String!) {
  createItem: create${templateType}Attachment (paperType: $paperType, paperId: $paperId, filename: $filename)
}`

export const DESTROY_ATTACHMENT = (templateType) => gql`mutation DESTROY_ATTACHMENT ($paperType: String!, $paperId: Int!, $filename: String!) {
  deleteItem: destroy${templateType}Attachment (paperType: $paperType, paperId: $paperId, filename: $filename)
}`
