import gql from 'graphql-tag'

export const DROPDOWN_CHILDREN = (templateType) => gql`query DROPDOWN_CHILDREN ($paperType: String!, $paperId: Int!, $q: FilterInput) {
  papers: list${templateType}DropdownChildren (paperType: $paperType, paperId: $paperId, q: $q) {
    id code name
  }
}`

export const CREATE_PAPER_LINK = (templateType) => gql`mutation CREATE_PAPER_LINK ($paperType: String!, $parentId: Int!, $childId: Int!) {
  create${templateType}Link (paperType: $paperType, parentId: $parentId, childId: $childId) {
    parentId childId
  }
}`

export const DESTROY_PAPER_LINK = (templateType) => gql`mutation DESTROY_PAPER_LINK ($paperType: String!, $parentId: Int!, $childId: Int!) {
  destroy${templateType}Link (paperType: $paperType, parentId: $parentId, childId: $childId) {
    parentId childId
  }
}`
