<template>
  <sgv-input-text
    disabled
    :value="value">
    <label slot="label">
      {{title}}
      <span
        v-if="isSubmit"
        class="px-1 pointer"
        @click="toggle = true">
        <fa icon="check" class="text-success"></fa>
      </span>
      <span
        class="px-1 pointer"
        v-if="isUndo"
        @click="toggle = true">
        <fa icon="times" class="text-danger"></fa>
      </span>

      <b-modal
        v-model="toggle"
        :title="title"
        hide-footer>
        <div v-if="isSubmit">
          <div class="form-row">
            <sgv-input-date
              isNull
              class="col-12"
              v-model="date"
              label="วันที่"
              :validations="[
                {text: 'required!', value: !$v.date.required && $v.date.$dirty}
              ]">
            </sgv-input-date>
          </div>

          <button
            type="button"
            class="btn btn-success btn-block"
            @click="submitData">
            อนุมัติ
          </button>
        </div>

        <div v-if="isUndo">
          <button
            type="button"
            class="btn btn-danger btn-block"
            @click="undoData">
            ยกเลิกการอนุมัติ
          </button>
        </div>

      </b-modal>
    </label>
  </sgv-input-text>
</template>

<script>
import { required } from 'vuelidate/lib/validators'

export default {
  name: 'DetailFormUser',
  props: {
    title: {
      type: String,
      required: true
    },
    value: {
      type: String,
      required: true
    },
    isSubmit: {
      type: Boolean,
      required: false
    },
    isUndo: {
      type: Boolean,
      required: false
    },
    submitFunc: {
      type: Function,
      required: true
    },
    undoFunc: {
      type: Function,
      required: true
    }
  },
  data () {
    return {
      toggle: false,
      date: this.$date.current().date
    }
  },
  validations: {
    date: {required}
  },
  methods: {
    toggleApprove () {
      this.toggle = true
    },
    submitData () {
      this.$v.$touch()
      if (this.$v.$invalid) return

      this.submitFunc(this.date)
      .then(() => {
        this.$v.$reset()
        this.toggle = false
        this.$toasted.global.success("อนุมัติสำเร็จ")
        this.$emit('updated', null)
      })
      .catch(err => {
        this.$toasted.global.error(err)
      })
    },
    undoData () {
      this.undoFunc()
      .then(() => {
        this.toggle = false
        this.$toasted.global.success("ยกเลิกสำเร็จ")
        this.$emit('updated', null)
      })
      .catch(err => {
        this.$toasted.global.error(err)
      })
    },
  }
}
</script>

<style lang="css" scoped>
</style>
