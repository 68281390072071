<template>
  <div class="form-row">
    <sgv-input-check
      label="ลดขนาดไฟล์"
      class="mb-3"
      :value="isCompress"
      @input="emitCompress">
    </sgv-input-check>

    <div class="col-12">
      <div class="row justify-content-start">
        <div
          v-if="canCreate"
          class="col-12 dropbox mb-3"
          @click="$refs.fileInput.click()">
          <p class="text-success">
            <slot>เพิ่มไฟล์</slot>
          </p>
          <input
            type="file"
            ref="fileInput"
            class="input-file"
            multiple
            @change="onFileChange"/>
        </div>

        <div class="col-6 col-sm-4 col-md-3" v-for="(item, i) in items" :key="i">
          <img :src="item.src" class="img-fluid" alt="...">
          <div style="word-wrap: break-word;">
            {{item.filename}}
          </div>
          <div class="mt-1 mb-4">
            <span
              @click="getFilename(item.filename)"
              style="font-size: 20px;"
              class="mr-2">
              <fa icon="copy" class="text-primary pointer"></fa>
            </span>

            <span
              @click="getMarkdown(item.filename)"
              style="font-size: 20px;"
              class="mr-2">
              <fa :icon="['fab', 'markdown']" class="text-warning pointer"></fa>
            </span>

            <span
              style="font-size: 20px;"
              class="mr-2"
              @click="getLink(item.filename)">
              <fa icon="link" class="text-info pointer"></fa>
            </span>

            <span
              style="font-size: 20px;"
              class="mr-2"
              @click="download(item.filename)">
              <fa icon="download" class="text-primary pointer"></fa>
            </span>

            <span
              style="font-size: 20px;"
              class="float-right"
              v-if="isDestroy">
              <fa
                icon="trash"
                class="text-danger pointer"
                @dblclick="deleteObject(item.filename)">
              </fa>
            </span>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import axios from 'axios'
import Compressor from 'compressorjs'
import {
  LIST_ATTACHMENT,
  DETAIL_ATTACHMENT,
  CREATE_ATTACHMENT,
  DESTROY_ATTACHMENT
} from '../graph/attachment'

export default {
  name: 'Attachment',
  props: {
    paperId: {
      type: Number,
      required: true
    },
    templateType: {
      type: String,
      required: true
    },
    paperType: {
      type: String,
      required: true
    },
    isPublic: {
      type: Boolean,
      required: false
    },
    isCompress: {
      type: Boolean,
      required: false,
      default: true
    },
    canDestroy: {
      type: Boolean,
      required: false
    }
  },
  data () {
    return {
      items: [],
      accept: 'image/*',
      publicPath: process.env.BASE_URL
    }
  },
  computed: {
    canCreate () {
      return this.$auth.hasRole(`paper:${this.paperType}:add`)
    },
    isDestroy () {
      return this.canDestroy && this.$auth.hasRole(`paper:${this.paperType}:add`)
    }
  },
  methods: {
    fetchList () {
      this.$apollo.query({
        query: LIST_ATTACHMENT(this.templateType),
        variables: {
          paperType: this.paperType,
          paperId: this.paperId
        },
        fetchPolicy: 'network-only'
      }).then(res => {
        const arr = res.data.items.map(async filename => {
          const src = await this.fetchCover(filename)
          return {src, filename}
        })
        Promise.all(arr).then(v => {
          this.items = v
        })
      })
      .catch(err => {
        this.$toasted.global.error(err)
      })
    },
    fetchCover (filename) {
      let extension = filename.split('.').pop()
      if (['jpg', 'jpeg', 'png', 'svg'].includes(extension.toLowerCase())) {
        return this.$apollo.query({
          query: DETAIL_ATTACHMENT(this.templateType),
          variables: {
            paperType: this.paperType,
            paperId: this.paperId,
            filename
          },
          fetchPolicy: 'network-only'
        })
        .then(res => res.data.url)
        .catch(() => '')
      }
    },
    getFilename (filename) {
      this.$copyText(filename)
    },
    getLink (filename) {
      this.$apollo.query({
        query: DETAIL_ATTACHMENT(this.templateType),
        variables: {
          paperType: this.paperType,
          paperId: this.paperId,
          filename
        },
        fetchPolicy: 'network-only'
      })
      .then(res => {
        this.$copyText(res.data.url)
      })
      .catch(() => '')
    },
    getLine (filename) {
      this.$apollo.query({
        query: DETAIL_ATTACHMENT(this.templateType),
        variables: {
          paperType: this.paperType,
          paperId: this.paperId,
          filename
        },
        fetchPolicy: 'network-only'
      })
      .then(res => {
        const url = res.data.url
        const a = window.document.createElement('a')
        a.href = `https://line.me/R/msg/text/?${url}`
        a.click()
      })
      .catch(() => '')
    },
    getMarkdown (filename) {
      this.$copyText(`![ชื่อรูปภาพ](${filename})`)
    },
    deleteObject (filename) {
      this.$apollo.mutate({
        mutation: DESTROY_ATTACHMENT(this.templateType),
        variables: {
          paperType: this.paperType,
          paperId: this.paperId,
          filename
        }
      })
      .then(() => {
        this.$toasted.global.success("ลบสำเร็จ")
        const index = this.items.findIndex(v => v.filename === filename)
        this.items.splice(index, 1)
      }).catch(err => {
        this.$toasted.global.error(err)
      })
    },
    onFileChange (e) {
      var fileInput = e.target.files
      this.compressFile(fileInput)
    },
    compressFile (files) {
      for (let key in files) {
        if (key !== 'length' && key !== 'item') {
          let file = files[key]
          let type = file.type
          let vm = this

          if (['image/jpeg', 'image/jpg', 'image/png'].includes(type) && file.size >= 200000 && this.isCompress) {
            console.log('compressing file...');
            new Compressor(file, {
              quality: 0.4,
              convertSize: 400000,
              maxHeight: 500,
              success(res) {
                vm.upload(res)
              }
            })
          } else {
            vm.upload(file)
          }
        }
      }
    },
    checkType (files) {
      let arr = []
      for (let key in files) {
        if (key !== 'length' && key !== 'item') {
          let inputAccept = this.accept.split(',')
          let typeAccept = files[key].type.split('/')
          let match = inputAccept.some(v => {
            let app = v.split('/')
            if(app[0].trim() !== typeAccept[0].trim()) return false
            if(app[1].trim() == '*') return true
            if(app[1].trim() !== typeAccept[1].trim()) return false
            return true
          })
          arr.push(match)
        }
      }
      return arr.every(v => v)
    },
    upload (file) {
      this.$apollo.mutate({
        mutation: CREATE_ATTACHMENT(this.templateType),
        variables: {
          paperType: this.paperType,
          paperId: this.paperId,
          filename: file.name
        }
      })
      .then(res => {
        const config = res.data.createItem
        let formData = new FormData()

        formData.append('acl', this.isPublic ? 'public-read' : 'private')
        formData.append('Content-Type', file.type)
        Object.keys(config.fields).forEach(k => {
          formData.append(k, config.fields[k])
        })
        formData.append('file', file, file.name);

        axios.post(config.url, formData, {
          headers: {
            'Content-Type': 'multipart/form-data'
          }
        }).then(() => {
          this.$toasted.global.success("เพิ่มสำเร็จ")
          this.fetchList()
        }).catch(() => {
          this.$toasted.global.error("ไม่สามารถอัพโหลดได้")
        })
      })
      .catch(() => {
        this.$refs.fileInput.value = ''
        this.$toasted.global.error("ไม่สามารถอัพโหลดได้")
      })
    },
    download (filename) {
      this.$apollo.query({
        query: DETAIL_ATTACHMENT(this.templateType),
        variables: {
          paperType: this.paperType,
          paperId: this.paperId,
          filename,
          isDownload: true
        },
        fetchPolicy: 'no-cache'
      })
      .then(res => {
        return axios.get(res.data.url, {responseType: 'blob'})
      })
      .then(res => {
        const a = window.document.createElement('a')
        a.href = window.URL.createObjectURL(res.data)
        a.download = filename
        a.click()
      })
      .catch(() => {
        this.$toasted.global.error("ไม่สามารถโหลดได้")
      })
    },
    emitCompress (value) {
      this.$emit('update:isCompress', value)
    }
  },
  created() {
    this.fetchList()
  }
}
</script>

<style lang="css" scoped>
.image-width {
  width: 150px;
}

.dropbox {
  outline: 3px dashed grey; /* the dash box */
  outline-offset: -5px;
  color: dimgray;
  position: relative;
  cursor: pointer;
}

.dropbox p {
  font-size: 1.2em;
  text-align: center;
  padding: 15px;
  margin: auto;
}

.input-file {
  opacity: 1; /* invisible but it's there! */
  width: 0px;
  height: 0px;
  position: absolute;
  cursor: pointer;
  visibility: hidden;
}
</style>
